import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {EthersService} from '../ethers/services/ethers.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient
  ) {

    // this.requestCode('0xf3c15220741F79aEC1e92a5E3C922C78bA4012C5').subscribe();
    // this.login('k7ggyqf158mppn53xv73c').subscribe()
  }

  requestCode(address: string) {
    return this.http.post<any>('https://api-nft.zertifier.com/api/request-code', {address});
  }

  login(signature: string, address: string) {
    console.log(this.http.post<any>('https://api-nft.zertifier.com/api/login', {signature, address}), "this.http.post<any>('https://api-nft.zertifier.com/api/login', {signature, address})");
    return this.http.post<any>('https://api-nft.zertifier.com/api/login', {signature, address});
  }

  public get loggedIn(): boolean {
    return localStorage.getItem('accessToken') !== null;
  }

/*  profile(address: string){
    console.log("a");
    console.log(this.http.post<any>('https://api-nft.zertifier.com/api/profile', {address}), "this.http.post<any>('https://api-nft.zertifier.com/api/login', {signature, address})");
    console.log("b");
    return this.http.post<any>('https://api-nft.zertifier.com/api/profile', {address});
  }*/

  logout() {
    localStorage.removeItem('accessToken');
  }
}
