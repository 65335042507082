import {ApplicationRef, Injectable, NgZone} from '@angular/core';
import {ContractsDatatablesService} from './contracts-datatables.service';

import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
// import {SmartContractFormComponent} from '../../views/pages/contracts/smart-contract-form/contract-form.component';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {UtilsService} from '../utils/utils.service';
import {ShippingDataModalComponent} from '../../views/partials/shipping-data-modal/shipping-data-modal.component';
import {EthersService} from '../ethers/services/ethers.service';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ContractsService {
  constructor(
    public contractsDatatables: ContractsDatatablesService,
    public ethersService: EthersService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private http: HttpClient,
    private appRef: ApplicationRef,
    public router: Router,
    private ngZone: NgZone,
    private utils: UtilsService,
    // private shippingModal: ShippingDataModalComponent
  ) {

    $(document).on('click', '.del-contract-btn', (e) => {
      const id = $(e.currentTarget).data('id');
      this.delContract(id);
    });

    $(document).on('click', '.send-btn', (e) => {
      const tokenId = $(e.currentTarget).data('id');
      const contractAddress = $(e.currentTarget).data('contract');
      this.contractsDatatables.setStatus(tokenId, contractAddress, 'sent');
      this.sendAlert();
    });

    $(document).on('click', '.manage-btn', async (event) => {
      // console.log(e);
      const tokenId = $(event.currentTarget).data('id');
      const contractAddress = $(event.currentTarget).data('contract');
      const status = $(event.currentTarget).data('status');
      const signature = $(event.currentTarget).data('signature');
      const chainId = $(event.currentTarget).data('chain');
      // const tokenOwner = await ethersService.getTokenOwner(tokenId, contractAddress);

      this.contractsDatatables.getFormdata(tokenId, contractAddress)
        .then((res) => {
          // console.log(res, "res");
          // const hashedData = ethersService.hashMessage(res);
          // const signedData = ethersService.signMessage(hashedData);
          // const walletFromHash  = ethersService.verifyMessage(hashedData, signature);
          const formModal = this.modalService.open(ShippingDataModalComponent);
          formModal.componentInstance.data = JSON.parse(res);
          formModal.componentInstance.dataString = res;
          formModal.componentInstance.tokenId = tokenId;
          formModal.componentInstance.contractAddress = contractAddress;
          formModal.componentInstance.status = status;
          formModal.componentInstance.signature = signature;
          formModal.componentInstance.chainId = chainId;
          appRef.tick();
          /*if (walletFromHash === tokenOwner){


          }else{
            Swal.fire({
              icon: 'error',
              title: 'Error verifying token owner.',
              text: 'Shipping wallet and token owner are NOT the same.',
            });
          }*/
        });


    });
  }

  renderDatatables(element, address: any, chainName: string) {
    this.contractsDatatables.renderDatatable(element, address, chainName);
  }

  refreshDatatables() {
    this.contractsDatatables.refreshDatatables();
  }

  search(value: string) {
    this.contractsDatatables.search(value);
  }

  refreshTable() {
    this.contractsDatatables.refreshTable();
  }

  delContract(id) {
    if (id) {
      this.translate.get([
        'generic.yes',
        'generic.no',
      ]).subscribe((trans: any) => {
        // @ts-ignore
        Swal.fire({
          title: 'Are you sure?',
          text: 'Do you really want to delete this petition?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: trans['generic.yes'],
          cancelButtonText: trans['generic.no']
        }).then((result) => {
          if (result.value) {

            this.http.delete(
              // 'https://api-nft.zertifier.com/api/smart-contract/' + id
              // 'http://localhost:3000/shippingStatus/' + id
              'http://46.253.45.22:3010/shippingStatus/' + id
            ).subscribe((res: any) => {
              if (res === true){
                this.refreshDatatables();

                Swal.fire(
                  'Done',
                  'Petition deleted successfully',
                  'success'
                ).then(() => {
                  this.modalService.dismissAll();
                });
              }else{
                Swal.fire(
                  'Error',
                  'Error deleting petition',
                  'error'
                );
              }
            });
          }
        });
      });
    }
  }

  sendAlert(){
    Swal.fire({
      // position: 'top-end',
      icon: 'success',
      title: 'The NFT has been sent.',
      showConfirmButton: false,
      timer: 2500
    }).then(() => {
      this.contractsDatatables.refreshDatatables();
    });
  }

}
