import { Component } from '@angular/core';
import {TranslationService} from './core/lang/translation.service';
import {locale as enLang} from './core/lang/i18n/en';
import {locale as esLang} from './core/lang/i18n/es';
import {locale as catLang} from './core/lang/i18n/cat';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ZERTINFT';

  constructor(
    public translationService: TranslationService,
  ) {
    // this.translationService.loadTranslations(enLang, catLang, esLang);
    this.translationService.loadTranslations(enLang);
    this.translationService.setLanguage(this.translationService.getSelectedLanguage());
  }
}
