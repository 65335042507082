import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {

  transform(value: string, replacements: ReplaceInterface[]): string {
    for (const replacement of replacements) {
      value = value.split(replacement.search).join(replacement.replaceWith);
    }
    return value;
  }

}

interface ReplaceInterface {
  search: string;
  replaceWith: string;
}
