import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minifyText'
})
export class MinifyTextPipe implements PipeTransform {

  transform(value: string, charsQty: number = 6): string {
    let minifiedText = value;
    if (value && value.length > charsQty * 2) {
      const firstChars = value.substr(0, charsQty);
      const lastChars = value.substr(value.length - charsQty, value.length);
      minifiedText = firstChars + '...' + lastChars;
    }
    return minifiedText;
  }

}
