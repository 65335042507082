import {ChangeDetectorRef, Injectable, ApplicationRef} from '@angular/core';
import {ContractFactory, ethers} from 'ethers';
import {BehaviorSubject, Subject} from 'rxjs';
import Swal from 'sweetalert2';
import networks from '../networks';
import * as abiObj from 'src/app/core/ethers/contract/abi.json';
import * as bytecodeObj from 'src/app/core/ethers/contract/bytecode.json';
import * as CryptoJS from 'crypto-js';
// import EthCrypto from 'eth-crypto';
import {ApiService} from '../../api/api.service';
import {UtilsService} from '../../utils/utils.service';
import {EncryptionService} from '../../utils/encryption.service';
import {ConnectMethodType} from '../types/connectMethod.type';
import {LoginComponent} from '../../../views/partials/login/login.component';
import {SignupComponent} from '../../../views/partials/signup/signup.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NetworkInterface, NetworkObjectInterface} from '../interfaces/network.interface';
import {TranslationService} from '../../lang/translation.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../auth/auth.service';
import {first, switchAll} from 'rxjs/operators';
import {Event, NavigationStart, Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';

declare let window: any;
declare let web3: any;

@Injectable({
  providedIn: 'root'
})
export class EthersService {
  public connectMethod: ConnectMethodType;
  public webWallet: any;
  public decodePass: string;
  networks: NetworkObjectInterface = networks;
  currentContract: NetworkInterface;
  network: NetworkInterface;
  profile: any = {};
  profile$ = new BehaviorSubject<any>(null);
  provider: any;
  contract: any;
  signer: any;
  abi: string;
  bytecode: string;
  wallet: string = '';
  $wallet = new BehaviorSubject<any>('');
  allowedAddress: boolean;
  allowedAddress$ = new BehaviorSubject<boolean>(false);
  balance: string;
  asymmetricWalletPrivateKey: string;
  asymmetricAccount = {
    publicKey: '',
    privateKey: '',
  };
  asymmetricAccount$ = new BehaviorSubject<any>({
    publicKey: '',
    privateKey: '',
  });
  standardNetworkNames = [
    'homestead',
    'rinkeby',
    'ropsten',
    'kovan',
    'goerli',
  ];
  public walletConnectNetworks = {
    mainnet: {
      chainId: 1
    },
    homestead: {
      chainId: 1
    },
    ropsten: {
      chainId: 3
    },
    rinkeby: {
      chainId: 4
    },
    xdai: {
      chainId: 100,
      JsonRpcProvider: 'https://rpc.ankr.com/gnosis'
    },
    polygon: {
      chainId: 137,
      JsonRpcProvider: 'https://polygon-rpc.com'
    },
    bnb: {
      chainId: 56,
      JsonRpcProvider: 'https://bsc-dataseed.binance.org'
    },
    bnbTestnet: {
      chainId: 97,
      JsonRpcProvider: 'https://data-seed-prebsc-1-s1.binance.org:8545'
    }
  };

  constructor(
    public apiService: ApiService,
    public encryptionService: EncryptionService,
    public utils: UtilsService,
    public modalService: NgbModal,
    public translate: TranslateService,
    public authService: AuthService,
    private appRef: ApplicationRef,
    private router: Router,
    private http: HttpClient
  ) {
    this.abi = (abiObj as any).default;
    this.bytecode = (bytecodeObj as any).default;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {

        this.updateTokensBalances();
      }

    });

    this.loadSavedConfig().then(() => {
      // console.log(typeof window.ethereum, 'typeof window.ethereum');
      if (typeof window.ethereum !== 'undefined') {
        window.ethereum.on('accountsChanged', async (accounts) => {
          // location.reload();
          // this.logout();
          if (this.connectMethod === 'METAMASK') {
            this.asymmetricAccount$.next({
              privateKey: '',
              publicKey: ''
            });
            await this.metamaskConnect();
            this.appRef.tick();
          }
          // this.signer = this.provider.getSigner();
          // this.wallet = accounts[0];
          // this.getBalance();
          // this.$wallet.next(this.wallet);
          // this.updateTokensBalances();

        });
        window.ethereum.on('chainChanged', async (chainId) => {
          // location.reload();
          // Handle the new chain.
          // Correctly handling chain changes can be complicated.
          // We recommend reloading the page unless you have good reason not to.
          if (this.connectMethod === 'METAMASK') {
            await this.metamaskConnect();
            this.appRef.tick();
          }

          this.getBalance();

        });
      }
    });

    this.$wallet.subscribe((wallet) => {
      this.getBalance();

      if (wallet) {
        this.updateIsAllowed();
      }

    });


    this.asymmetricAccount$.subscribe((asymmetricAccount) => {
      this.asymmetricWalletPrivateKey = asymmetricAccount.privateKey;
      this.asymmetricAccount.publicKey = asymmetricAccount.publicKey;
      this.asymmetricAccount.privateKey = asymmetricAccount.privateKey;
    });

    this.profile$.subscribe((profile) => {
      this.profile = {...profile};
    });
  }

  updateIsAllowed() {
    this.apiService.isAllowed(this.wallet).subscribe((isAllowed: boolean) => {
      this.allowedAddress = isAllowed;
      this.allowedAddress$.next(this.allowedAddress);
    });
  }

  startFreeTrial() {
    this.apiService.startFreeTrial(this.wallet).subscribe((res: any) => {
      if (res.status === 'success') {
        this.updateIsAllowed();
      }
    });
  }


  loadSavedConfig(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.getSavedConnectMethod();
      this.getSavedNetwork();
      resolve();
    });
  }

  login(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (!this.signer) {
        if (this.connectMethod === 'METAMASK') {
          if (typeof window.ethereum !== 'undefined') {
            try {
              await window.ethereum.request({method: 'eth_requestAccounts'});
              if (await this.setContract()) {
                this.signer = this.provider.getSigner();
                this.wallet = await this.signer.getAddress();

                this.getBalance();

                this.$wallet.next(this.wallet);
                this.updateTokensBalances();
                resolve();
              } else {
                reject('contractNotExists');
              }
            } catch (error) {
              reject('permissionRejected');
            }
          } else {
            reject('metamaskNotInstalled');
          }
        } else if (this.connectMethod === 'WEBWALLET') {
          if (this.getSavedKey()) {
            this.modalService.open(LoginComponent, {scrollable: true});
          } else {
            this.modalService.open(SignupComponent, {size: 'lg', scrollable: true});

          }
        }
      }

    });
  }

  saveKey(password: string) {
    this.decodePass = password;
    localStorage.setItem('encryptedKey', String(this.encryptionService.encrypt(this.webWallet.privateKey, password)));
  }

  clearKey() {
    localStorage.removeItem('encryptedKey');
  }

  getSavedKey() {
    return localStorage.getItem('encryptedKey');
  }

  saveConnectMethod(connectMethod: ConnectMethodType) {
    this.connectMethod = connectMethod;
    localStorage.setItem('connectMethod', this.connectMethod);
  }

  clearConnectMethod() {
    this.connectMethod = null;
    localStorage.removeItem('connectMethod');
  }

  getSavedConnectMethod() {
    this.connectMethod = localStorage.getItem('connectMethod') as ConnectMethodType;
    return this.connectMethod;
  }

  saveNetwork(network: any) {
    this.network = network;
    localStorage.setItem('network', JSON.stringify(this.network));
  }

  clearNetwork() {
    this.network = null;
    localStorage.removeItem('network');
  }

  getSavedNetwork(): NetworkInterface {
    this.network = JSON.parse(localStorage.getItem('network'));
    return this.network;
  }

  async updateTokensBalances() {
    if (this.network) {
      for (let i = 0; i < Object.keys(networks[this.network.chainId].tokens).length; i++) {
        const token = Object.keys(networks[this.network.chainId].tokens)[i];
        await this.updateTokenBalance(token);
      }
    }
  }

  async updateTokenBalance(token) {
    networks[this.network.chainId].tokens[token].balance = parseFloat(await this.getTokenBalance(token));
  }

  async getBalance() {
    if (this.signer) {
      this.balance = ethers.utils.formatEther(await this.signer.getBalance());
      return this.balance;
    } else {
      return '';
    }
  }

  getFreeToken(coin) {
    return new Promise((resolve, reject) => {
      const contract = new ethers.Contract(
        networks[this.network.chainId].tokens[coin.toLowerCase()].tokenAddress,
        networks[this.network.chainId].tokens[coin.toLowerCase()].abi,
        this.signer
      );
      contract.mint(this.wallet, ethers.utils.parseUnits('1000', 18)).then((tx) => {
        tx.wait()
          .then((data) => {
            this.updateTokenBalance(coin.toLowerCase())
              .then(() => resolve())
              .catch(() => reject());
          })
          .catch(() => reject());
      }).catch((e) => {
        reject(e);
      });
    });
  }

  getTokenOwner(tokenId: any, contractAddress: any, chainId: any) {
    const provider = ethers.getDefaultProvider(parseInt(chainId))
    const contract = new ethers.Contract(
      contractAddress,
      this.abi,
      provider
    );
    return contract.ownerOf(tokenId).then((res: any) => {
      return contract.ownerOf(tokenId);
    });
  }

  async getTokenUri(tokenId: any, contractAddress: any, chainId) {
    const provider = ethers.getDefaultProvider(parseInt(chainId))
    let contract = new ethers.Contract(
      contractAddress,
      this.abi,
      provider
    );
    return await contract.tokenURI(tokenId);
  }

  pay(hash, coin, quantity, senderAddress, receiverPublicKey) {

    return new Promise(async (resolve, reject) => {
      this.translate.get([
        'receive.alerts.invoicePayment.title',
        'receive.alerts.invoicePayment.text',
        'receive.alerts.balanceError.title',
        'receive.alerts.balanceError.text',
        'generic.accept',
        'generic.cancel',
      ])
        .subscribe(async (lang) => {
          const balance = parseFloat(await this.getBalance());


          Swal.fire({
            title: lang['receive.alerts.invoicePayment.title'],
            text: lang['receive.alerts.invoicePayment.text'],
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: lang['generic.accept'],
            cancelButtonText: lang['generic.cancel']
          }).then((result) => {
            if (result.value) {

              switch (coin) {
                case 'ETH':
                  if (balance >= quantity) {
                    this.signer.sendTransaction({
                      to: senderAddress,
                      value: ethers.utils.parseEther(quantity.toString())
                    }).then((tx) => {
                      this.apiService.setTransaction(hash, tx.hash, senderAddress, receiverPublicKey).subscribe(() => {
                        Swal.fire({
                          icon: 'success',
                          title: 'Payment completed',
                          text: 'Payment process has been successfully completed. Waiting to be mined...'
                        });
                        resolve();
                      });
                    });
                  } else {
                    Swal.fire({
                      icon: 'error',
                      title: lang['receive.alerts.balanceError.title'],
                      text: lang['receive.alerts.balanceError.text'],
                    });

                  }
                  break;
                case 'BNB':
                  if (balance >= quantity) {
                    this.signer.sendTransaction({
                      to: senderAddress,
                      value: ethers.utils.parseEther(quantity.toString())
                    }).then((tx) => {
                      this.apiService.setTransaction(hash, tx.hash, senderAddress, receiverPublicKey).subscribe(() => {
                        Swal.fire({
                          icon: 'success',
                          title: 'Payment completed',
                          text: 'Payment process has been successfully completed. Waiting to be mined...'
                        });
                        resolve();
                      });
                    });
                  } else {
                    Swal.fire({
                      icon: 'error',
                      title: lang['receive.alerts.balanceError.title'],
                      text: lang['receive.alerts.balanceError.text'],
                    });

                  }
                  break;
                case 'XDAI':
                  if (balance >= quantity) {
                    this.signer.sendTransaction({
                      to: senderAddress,
                      value: ethers.utils.parseEther(quantity.toString())
                    }).then((tx) => {
                      this.apiService.setTransaction(hash, tx.hash, senderAddress, receiverPublicKey).subscribe(() => {
                        Swal.fire({
                          icon: 'success',
                          title: 'Payment completed',
                          text: 'Payment process has been successfully completed. Waiting to be mined...'
                        });
                        resolve();
                      });
                    });
                  } else {
                    Swal.fire({
                      icon: 'error',
                      title: lang['receive.alerts.balanceError.title'],
                      text: lang['receive.alerts.balanceError.text'],
                    });

                  }
                  break;
                case 'DAI':
                  this.transferToken(coin, senderAddress, quantity.toString(), hash, receiverPublicKey)
                    .then(() => resolve())
                    .catch(() => reject());
                  break;
                case 'CTRO':
                  this.transferToken(coin, senderAddress, quantity.toString(), hash, receiverPublicKey)
                    .then(() => resolve())
                    .catch(() => reject());
                  break;
                case 'USDT':
                  this.transferToken(coin, senderAddress, quantity.toString(), hash, receiverPublicKey)
                    .then(() => resolve())
                    .catch(() => reject());
                  break;
                case 'WBTC':
                  this.transferToken(coin, senderAddress, quantity.toString(), hash, receiverPublicKey)
                    .then(() => resolve())
                    .catch(() => reject());
                  break;
                case 'GEUR':
                  this.transferToken(coin, senderAddress, quantity.toString(), hash, receiverPublicKey)
                    .then(() => resolve())
                    .catch(() => reject());
                  break;
              }
            }
          });
        });
    });
  }

  transferToken(coin, senderAddress, quantity, hash, receiverPublicKey) {
    return new Promise((resolve, reject) => {
      if (!networks.hasOwnProperty(this.network.chainId)) {
        Swal.fire({
          icon: 'error',
          title: 'Transfer canceled',
          text: 'We do not support this Network ' + (this.network.name ? '(' + this.network.name.toUpperCase() + ')' : '')
        });
        reject();
      } else if (!networks[this.network.chainId].tokens.hasOwnProperty(coin.toLowerCase())) {
        Swal.fire({
          icon: 'error',
          title: 'Transfer canceled',
          text: 'We do not support this Coin ' +
            (coin ? '(' + coin.toUpperCase() + ')' : '') +
            ' for the selected Network ' +
            (this.network.name ? '(' + this.network.name.toUpperCase() + ')' : '')
        });
        reject();
      } else {
        const contract = new ethers.Contract(
          networks[this.network.chainId].tokens[coin.toLowerCase()].tokenAddress,
          networks[this.network.chainId].tokens[coin.toLowerCase()].abi,
          this.signer
        );
        contract.balanceOf(this.wallet).then((balance) => {
          const qty = ethers.utils.parseUnits(quantity, networks[this.network.chainId].tokens[coin.toLowerCase()].decimals);
          const hasBalance = qty.lte(balance);
          if (hasBalance) {
            contract.transfer(senderAddress, qty)
              .then((tx) => {
                this.apiService.setTransaction(hash, tx.hash, senderAddress, receiverPublicKey).subscribe(() => {
                  Swal.fire({
                    icon: 'success',
                    title: 'Payment completed',
                    text: 'Payment process has been successfully completed. Waiting to be mined...'
                  });
                  resolve();
                });
              })
              .catch(() => {
                Swal.fire({
                  icon: 'error',
                  title: 'Transfer canceled',
                  text: 'You have canceled the transfer'
                });
                reject();
              });
          } else {
            Swal.fire({
              icon: 'error',
              title: `Insufficient ${coin}s`,
              text: `Your don\'t have enought ${coin}s`
            });
            reject();
          }
        }).catch((e) => {
          reject(e);
        });
      }
    });
  }


  getTokenBalance(coin): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.signer) {
        const contract = new ethers.Contract(
          networks[this.network.chainId].tokens[coin.toLowerCase()].tokenAddress,
          networks[this.network.chainId].tokens[coin.toLowerCase()].abi,
          this.signer
        );
        contract.balanceOf(this.wallet)
          .then((balance) => {
            const qty = ethers.utils.formatUnits(balance, networks[this.network.chainId].tokens[coin.toLowerCase()].decimals);
            resolve(qty);
          })
          .catch((e) => {
            reject(e);
          });
      } else {
        resolve('0');
      }

    });
  }

  importAsymmetricWallet() {
    return new Promise(async (resolve, reject) => {
      /*try {
        this.asymmetricAccount$.next({
          privateKey: '',
          publicKey: ''
        });

        const asymmetricAccountPassword = await this.signMessage('Access Hash4Life asymmetric account.\n\nOnly sign this message for a trusted client!');


        const privateKey = ethers.utils.id(asymmetricAccountPassword);
        const publicKey = EthCrypto.publicKeyByPrivateKey(privateKey);
        this.asymmetricAccount$.next({
          privateKey,
          publicKey
        });
        this.appRef.tick();


        resolve();

      } catch (e) {
        reject(e);
      }*/


    });
  }

  serverLogin(asymmetricAccountPassword) {
    // debugger
    return new Promise(async (resolve, reject) => {
      this.authService.login(asymmetricAccountPassword, this.wallet).pipe(first()).subscribe((loginRes) => {


        localStorage.setItem('accessToken', loginRes.token);
        this.profile$.next(loginRes);

        this.$wallet.next(this.wallet);
        this.asymmetricAccount$.next(this.asymmetricAccount);

        resolve();
      }, error => {

        reject(error);
      });
    });
  }

  signWithPrivateKey(privateKey, message) {
    const wallet = new ethers.Wallet(privateKey);
    return wallet.signMessage(message);
  }


  async setContract() {


    // this.provider = new ethers.providers.Web3Provider(web3.currentProvider);
    // await this.setNetwork();

    if (this.network && networks.hasOwnProperty(this.network.chainId)) {
      this.currentContract = networks[this.network.chainId];
      this.contract = new ethers.Contract(this.currentContract.contractAddress, this.abi, this.provider);
      return true;
    } else {
      return false;
    }
  }

  getProvider(chain = 'homestead') {
    let networkName = chain;
    if (networkName === 'mainnet') {
      networkName = 'homestead';
    }
    if (this.standardNetworkNames.includes(networkName)) {
      return ethers.getDefaultProvider(networkName);
    } else if (this.walletConnectNetworks[networkName]) {
      return new ethers.providers.JsonRpcProvider(this.walletConnectNetworks[networkName].JsonRpcProvider);
    } else {
      if (window.web3 && window.web3.currentProvider) {
        return new ethers.providers.Web3Provider(window.web3.currentProvider);
      }
    }
  }

  setNetwork(networkName = 'homestead') {

    return new Promise(async (resolve, reject) => {

      if (this.connectMethod === 'WEBWALLET') {

        if (networkName === 'mainnet') {
          networkName = 'homestead';
        }

        console.log(networkName, 'networkName');
        console.log(this.walletConnectNetworks, 'this.walletConnectNetworks');
        if (this.standardNetworkNames.includes(networkName)) {
          console.log('this.standardNetworkNames.includes(networkName)');
          this.provider = ethers.getDefaultProvider(networkName);
        } else if (this.walletConnectNetworks[networkName]) {
          console.log('this.walletConnectNetworks[networkName]');
          this.provider = new ethers.providers.JsonRpcProvider(this.walletConnectNetworks[networkName].JsonRpcProvider);
        } else {
          console.log('window.web3 && window.web3.currentProvider');
          if (window.web3 && window.web3.currentProvider) {
            this.provider = new ethers.providers.Web3Provider(window.web3.currentProvider);
          }
        }
        console.log(this.provider, 'this.provider');
        console.log(this.signer, 'this.signer');
        this.signer = this.signer.connect(this.provider);
      }

      const network = await this.provider.getNetwork();

      if (networks[network.chainId]) {
        network.name = networks[network.chainId].name;
        network.symbol = networks[network.chainId].symbol;
        network.icon = networks[network.chainId].icon;

        this.getBalance();
        this.saveNetwork(network);
        this.setContract();
        resolve();
      } else {
        this.logout();
        this.translate.get([
          'partials.networkNotSupportedAlert.title',
          'partials.networkNotSupportedAlert.text',
        ])
          .subscribe(async (trans) => {
            Swal.fire(trans['partials.networkNotSupportedAlert.title'], trans['partials.networkNotSupportedAlert.text']);
          });
        reject('Network not suported');
      }

    });

  }

  encrypt(data, password) {
    return CryptoJS.AES.encrypt(data, password).toString();
  }

  decrypt(data, password): Promise<any> {
    return new Promise((resolve, reject) => {
      const bytes = CryptoJS.AES.decrypt(data, password);

      resolve(bytes.toString(CryptoJS.enc.Utf8));
    });
  }

  signMessage(message) {
    return this.signer.signMessage(message);
  }

  verifyMessage(data, signature) {
    return ethers.utils.verifyMessage(data, signature);
  }

  hashMessage(data: string): string {
    const messageBytes = ethers.utils.toUtf8Bytes(data);
    return ethers.utils.keccak256(messageBytes);
  }


  createNFTcontract(name: string, symbol: string, baseTokenURI: string, baseContractURI: string): Promise<any> {

// The factory we use for deploying contracts
    const factory = new ContractFactory(this.abi, this.bytecode, this.signer);

    return factory.deploy(
      name,
      symbol,
      baseTokenURI,
      baseContractURI,
    );

  }


  getContractInfo(contractAddress: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const contract = new ethers.Contract(
        contractAddress,
        this.abi,
        this.signer
      );

      const baseContractURI = await contract.contractURI();
      const baseTokenURI = await contract.getBaseTokenURI();
      const name = await contract.name();
      const symbol = await contract.symbol();
      const owner = await contract.owner();


      resolve({
        baseContractURI,
        baseTokenURI,
        name,
        symbol,
        owner,
      });

    });
  }


  findToken(chain: string, contractAddress: string, nfc: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const provider = this.getProvider(chain);

        const contract = new ethers.Contract(
          contractAddress,
          this.abi,
          provider
        );

        const tokenId = await contract.getTokenIdFromNFC(nfc);
        const baseTokenURI = await contract.getBaseTokenURI();
        const tokenOwner = tokenId.toNumber() ? await contract.ownerOf(tokenId) : '';
        const contractOwner = await contract.owner();
        resolve({
          baseTokenURI,
          tokenId,
          tokenOwner,
          contractOwner
        });

      } catch (e) {
        reject(e);
      }

    });
  }


  tokenExist(contractAddress: string, tokenId): Promise<any> {
    return new Promise(async (resolve, reject) => {

      const contract = new ethers.Contract(
        contractAddress,
        this.abi,
        this.signer
      );

      const tokenOwner = await contract.ownerOf(tokenId);

      resolve(tokenOwner ? true : false);

    });
  }

  getNextTokenId(contractAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const contract = new ethers.Contract(
        contractAddress,
        this.abi,
        this.signer
      );

      contract.totalSupply()
        .then((totalSupply) => {
          console.log(totalSupply.toNumber(), 'totalSupply.toNumber()');
          if (!totalSupply.toNumber()) {
            resolve(1);
          }
          const index = totalSupply.toNumber() - 1;
          contract.tokenByIndex(index)
            .then((lastTokenId) => {
              resolve(lastTokenId.toNumber() + 1);
            })
            .catch((e) => {
              reject(e);
            });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }


  getTokenIdMetadataUri(contractAddress: string, tokenId: number): Promise<any> {
    console.log('vale');
    return new Promise((resolve, reject) => {
      const contract = new ethers.Contract(
        contractAddress,
        this.abi,
        this.signer
      );

      contract.tokenURI(tokenId)
        .then((tokenURI) => {
          // const httpOptions = {
          //   headers: new HttpHeaders({
          //     'Access-Control-Allow-Origin': '*',
          //     'Content-Type': 'application/json'
          //   })
          // };
          //
          // this.http.get(tokenURI, httpOptions).subscribe((metadata) => {
          //   console.log(metadata, 'metadata');
          resolve(tokenURI);
          // });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  createNFTtoken(contractAddress: string, tokenId: number, nfc: string, walletAddress: string): Promise<any> {
    return new Promise(async (resolve, reject) => {

      const gasPrice = await this.provider.getGasPrice();
// { BigNumber: "28131502112" }

// ...often this gas price is easier to understand or
// display to the user in gwei
      const gasPriceGW = ethers.utils.formatUnits(gasPrice, 'gwei');
      console.log('GAS PRICE ' + gasPriceGW + ' GWEI');
      console.log('GAS PRICE + 10% ' + (parseFloat(gasPriceGW) * 1.1) + ' GWEI');

      const contract = new ethers.Contract(
        contractAddress,
        this.abi,
        this.signer
      );

      if (!walletAddress) {
        walletAddress = this.wallet;
      }

      if (nfc) {
        contract.mintWithExternalId(walletAddress, tokenId, 'NFC', nfc)
          .then((tx) => {
            resolve(tx);
          })
          .catch((e) => {
            reject(e);
          });
      } else {
        contract.mint(walletAddress, tokenId)
          .then((tx) => {
            resolve(tx);
          })
          .catch((e) => {
            reject(e);
          });

      }

    });
  }


  transferNFTtoken(contractAddress: string, tokenId: number, walletAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {


      if (!walletAddress) {
        reject('walletAddress is required');
      }
      const contract = new ethers.Contract(
        contractAddress,
        this.abi,
        this.signer
      );


      contract.transferFrom(this.wallet, walletAddress, tokenId)
        .then((tx) => {
          resolve(tx);
        })
        .catch((e) => {
          reject(e);
        });


    });
  }

  transferNFTownership(contractAddress: string, walletAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {


      if (!walletAddress) {
        reject('walletAddress is required');
      }
      const contract = new ethers.Contract(
        contractAddress,
        this.abi,
        this.signer
      );


      contract.transferOwnership(walletAddress)
        .then((tx) => {
          this.apiService.transferSmartContract(this.network?.name, contractAddress, walletAddress).pipe(first()).subscribe(() => {
            resolve(tx);
          });
        })
        .catch((e) => {
          reject(e);
        });


    });
  }


  async saveHash(data: string, fileName: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.askForSaveHash().then(() => {
        const hash = this.hashMessage(data);

        const contractWithSigner = this.contract.connect(this.signer);
        contractWithSigner.getHashPrice()
          .then(hashPrice => {

            let params: any = {};
            if (hashPrice.toString()) {
              params['value'] = hashPrice;
            }

            contractWithSigner.setHash(hash, params)
              .then(async tx => {
                this.apiService.setHashTransaction(hash, tx.hash, this.wallet, this.network.name, fileName, this.currentContract.contractAddress)
                  .subscribe(() => {
                    resolve(tx);
                  }, error => {
                    console.error(error);
                    reject(error);
                  });
              })
              .catch(e => {
                console.error(e);
                reject(e);
              });
          })
          .catch(e => {
            console.error(e);
            reject(e);
          });
      }).catch((e) => {
        console.error(e);
        reject(e);
      });
    });
  }

  askForSaveHash() {
    return new Promise((resolve, reject) => {
      if (this.connectMethod === 'WEBWALLET') {
        Swal.fire({
          title: 'Are you sure?',
          text: 'To save a hash to the blockchain you will have to pay a little fee. Are you sure?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Accept',
          cancelButtonText: 'Decline'
        }).then((result) => {
          if (result.value) {
            resolve();
          } else {
            reject();
          }
        });
      } else {
        resolve();
      }
    });
  }

  getHashInfo(hash: string): Promise<any> {

    return new Promise((resolve, reject) => {
      if (this.contract) {
        this.contract.getHashInfo(hash)
          .then(hashInfo => {


            const owner = hashInfo[0];
            const creationDt = hashInfo[1];
            const exists = hashInfo[2];
            this.apiService.getHashTransaction(hash).subscribe((data: any) => {
              if (data) {
                resolve({
                  hash,
                  owner,
                  creationDt,
                  network: data?.network || '',
                  networks: data?.networks || [],
                  fileName: data?.fileName || '',
                  tx: data?.tx || '',
                  exists
                });
              } else {
                if (exists) {
                  const networksArr = [
                    {
                      hash,
                      owner,
                      creationDt,
                      network: this.contract.provider.network.name,
                      contract: this.contract.address,
                    }
                  ];


                  resolve({
                    hash,
                    owner,
                    creationDt,
                    networks: networksArr,
                    network: this.contract.provider.network.name,
                    contract: this.contract.address,
                    exists
                  });
                } else {
                  resolve(null);
                }

              }
            }, err => {
              if (exists) {
                const networksArr = [
                  {
                    hash,
                    owner,
                    creationDt,
                    network: this.contract.provider.network.name,
                    contract: this.contract.address,
                  }
                ];


                resolve({
                  hash,
                  owner,
                  creationDt,
                  networks: networksArr,
                  network: this.contract.provider.network.name,
                  contract: this.contract.address,
                  exists
                });
              } else {
                resolve(null);
              }
            });

          })
          .catch(e => resolve(null));
      } else {
        this.apiService.getHashTransaction(hash).subscribe((data: any) => {
          resolve(data);
        }, err => resolve(null));
      }
    });
  }

  async getHashInfoFromData(data: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // debugger
      const messageBytes = ethers.utils.toUtf8Bytes(data);
      const hash = ethers.utils.keccak256(messageBytes);

      this.getHashInfo(hash)
        .then(hashInfo => resolve(hashInfo))
        .catch(e => reject(e));
    });
  }

  public newWallet(password: string): any {
    this.webWallet = ethers.Wallet.createRandom();
    this.saveKey(password);
    this.webWalletConnect(this.webWallet);
    return this.webWallet;
  }

  async importKeystoreWallet(keystore: any, password: string) {
    try {
      this.webWallet = await ethers.Wallet.fromEncryptedJson(keystore, password);
      this.saveKey(password);
      return this.webWallet;
    } catch (error) {
      return false;
    }

  }

  importWalletFromPrivateKey(privateKey: string, password: string) {
    try {
      if (!privateKey.startsWith('0x')) {
        privateKey = '0x' + privateKey;
      }
      this.webWallet = new ethers.Wallet(privateKey);
      this.saveKey(password);
      return this.webWallet;
    } catch (error) {
      return false;
    }
  }

  importWalletFromMnemonic(mnemonic: string, password: string) {
    try {

      this.webWallet = ethers.Wallet.fromMnemonic(mnemonic);
      this.saveKey(password);
      return this.webWallet;
    } catch (error) {
      return false;
    }
  }


  async generateKeystore(password: string) {
    return await this.webWallet.encrypt(password);
  }

  importWalletFromStorage(password: string) {
    const privateKey = this.utils.decrypt(localStorage.getItem('encryptedKey'), password);
    this.webWallet = new ethers.Wallet(privateKey);
    this.webWalletConnect(this.webWallet);
  }

  async webWalletConnect(signer) {
    this.signer = signer;

    this.saveConnectMethod('WEBWALLET');
    // this.provider  = new ethers.providers.JsonRpcProvider('https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161');
    const savedNetwork = this.getSavedNetwork();
    if (savedNetwork) {

      await this.setNetwork(savedNetwork.name);
    } else {
      await this.setNetwork();
    }


    // this.provider = ethers.getDefaultProvider(savedNetwork?.name || 'homestead');

    // this.signer = signer.connect(this.provider);
    // await this.signer.connect(this.provider);


    this.signer.getAddress().then(async (wallet) => {

      this.wallet = wallet;

      this.$wallet.next(this.wallet);


      // const asymmetricAccountPassword = await this.signMessage('Access Zertidocs asymmetric account.\n\nOnly sign this message for a trusted client!');
      // this.authService.login(asymmetricAccountPassword, this.wallet).subscribe((loginRes) => {
      //   // console.log(loginRes, 'res');
      //   this.$wallet.next(this.wallet);
      //   this.profile$.next(loginRes);
      //   localStorage.setItem('accessToken', loginRes.token);
      //   this.importAsymmetricWallet(asymmetricAccountPassword);
      // });


    });
    // this.getBalance();
  }

  webWalletChangeNetwork(networkName) {

    // You can use any standard network name
    //  - "homestead"
    //  - "rinkeby"
    //  - "ropsten"
    //  - "kovan"
    //  - "goerli"

    if (this.standardNetworkNames.includes(networkName)) {

      this.provider = ethers.getDefaultProvider(networkName);
      this.signer = this.signer.connect(this.provider);
      this.setNetwork(networkName);
      this.getBalance();
    } else if (this.walletConnectNetworks[networkName]) {
      this.provider = new ethers.providers.JsonRpcProvider(this.walletConnectNetworks[networkName].JsonRpcProvider);
      this.signer = this.signer.connect(this.provider);
      this.setNetwork(networkName);
      this.getBalance();
    }


  }

  addChain(key) {
    if (key == 1 || key == 3) {
      return window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: '0x' + (parseInt(key)).toString(16)
        }]
      });

    } else {
      return window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: '0x' + (parseInt(key)).toString(16),
          chainName: networks[key].name,
          rpcUrls: networks[key].rpcUrl ? [
            networks[key].rpcUrl,
          ] : null,
          iconUrls: [
            window.location.hostname + networks[key].icon
          ],
          nativeCurrency: {
            name: networks[key].name,
            symbol: networks[key].symbol,
            decimals: networks[key].decimals
          }
        }]
      });

    }
  }

  async metamaskConnect() {

    this.saveConnectMethod('METAMASK');
    return new Promise(async (resolve, reject) => {
      // this.webWalletService.showWebWalletSignUp();
      if (typeof window.ethereum !== 'undefined') {
        try {
          await window.ethereum.request({method: 'eth_requestAccounts'});
          this.provider = new ethers.providers.Web3Provider(web3.currentProvider);

          await this.setNetwork();
          this.signer = this.provider.getSigner();
          this.wallet = await this.signer.getAddress();

          if (await this.setContract()) {

            this.getBalance();
            this.$wallet.next(this.wallet);
            this.updateTokensBalances();

            resolve();
          } else {
            this.$wallet.next(this.wallet);
            reject('contractNotExists');
          }

        } catch (error) {
          reject('permissionRejected');
        }

      } else {
        this.translate.get([
          'partials.metamaskNotInstalledAlert.title',
          'partials.metamaskNotInstalledAlert.text',
        ])
          .subscribe(async (trans) => {
            Swal.fire(trans['partials.metamaskNotInstalledAlert.title'], trans['partials.metamaskNotInstalledAlert.text']);
          });
        reject('metamaskNotInstalled');
      }
    });
    // this.provider = ethers.getDefaultProvider('ropsten');
    // this.signer = signer;
    // this.signer.getAddress().then((wallet) => {
    //   this.wallet = wallet;
    // });
  }

  requestNewToken() {
    return new Promise(async (resolve, reject) => {

      resolve();

    });

  }

  getENSAddress(ensName) {
    return new Promise((resolve, reject) => {
      const provider = new ethers.providers.InfuraProvider('homestead');
      provider.resolveName(ensName)
        .then((address) => {
          resolve(address);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  getAddressFromENS(address) {
    return new Promise((resolve, reject) => {
      const provider = ethers.getDefaultProvider('homestead');
      provider.lookupAddress(address)
        .then((ensName) => {
          resolve(ensName);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  logout() {
    this.authService.logout();
    this.clearConnectMethod();
    this.provider = null;
    this.signer = null;
    this.wallet = '';
    this.$wallet.next('');
    this.asymmetricAccount$.next({
      publicKey: '',
      privateKey: '',
    });
  }

}
