import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgbModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';
import {PipesModule} from './core/pipes/pipes.module';
import {CertificateModalComponent} from './views/partials/certificate-modal/certificate-modal.component';
import {ConnectModalComponent} from './views/partials/connect-modal/connect-modal.component';
import {TranslateModule} from '@ngx-translate/core';
import {ShowHideInputTextModule} from './views/partials/show-hide-input-text/show-hide-input-text.module';
import {QRCodeModule} from 'angularx-qrcode';
import {NewWalletComponent} from './views/partials/signup/new-wallet/new-wallet.component';
import {ImportWalletComponent} from './views/partials/signup/import-wallet/import-wallet.component';
import {ImportPrivateKeyComponent} from './views/partials/signup/import-private-key/import-private-key.component';
import {ImportMnemonicComponent} from './views/partials/signup/import-mnemonic/import-mnemonic.component';
import {SignupComponent} from './views/partials/signup/signup.component';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './views/partials/login/login.component';
import {WalletInfoModule} from './views/partials/wallet-info/wallet-info.module';
import {SpinnerButtonModule} from './views/partials/spinner-button/spinner-button.module';
import {QrExporterModalComponent} from './views/partials/qr-exporter-modal/qr-exporter-modal.component';
import {QrImporterModalComponent} from './views/partials/qr-importer-modal/qr-importer-modal.component';
import {InterceptService} from './core/utils/intercept.service';
import {SendEmailModalComponent} from './views/partials/send-email-modal/send-email-modal.component';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import { IdentityModalComponent } from './views/partials/identity-modal/identity-modal.component';
import {SignMessageBoxModule} from './views/partials/sign-message-box/sign-message-box.module';
import { ShippingDataModalComponent } from './views/partials/shipping-data-modal/shipping-data-modal.component';



@NgModule({
  declarations: [
    AppComponent,
    CertificateModalComponent,
    ConnectModalComponent,
    SignupComponent,
    ImportMnemonicComponent,
    ImportPrivateKeyComponent,
    ImportWalletComponent,
    NewWalletComponent,
    LoginComponent,
    QrExporterModalComponent,
    QrImporterModalComponent,
    SendEmailModalComponent,
    ShippingDataModalComponent,


  ],
  imports: [
    BrowserModule,
    PipesModule,
    TranslateModule.forRoot(),
    AppRoutingModule,
    ShowHideInputTextModule,
    QRCodeModule,
    HttpClientModule,
    NgbModule,
    WalletInfoModule,
    FormsModule,
    CommonModule,
    SpinnerButtonModule,
    ShowHideInputTextModule,
    FormsModule,
    RecaptchaV3Module,
    SignMessageBoxModule
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true
    }, {provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LfBhVAaAAAAAASNz8_naShFv8PAjzK_i7E_lmTh'}
  ],
  bootstrap:
    [AppComponent]
})

export class AppModule {
}
