import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EthersService} from '../ethers/services/ethers.service';
import {MinifyTextPipe} from '../pipes/minify-text.pipe';
import {TranslateService} from '@ngx-translate/core';
import Swal from 'sweetalert2';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
// import {SmartContractFormComponent} from '../../views/pages/contracts/smart-contract-form/contract-form.component';
import {DatatableLangService} from '../utils/datatable-lang.service';
import axios from 'axios';
import * as moment from 'moment';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ContractsDatatablesService {
  datatable: any;

  constructor(
    private http: HttpClient,
    private ethersService: EthersService,
    private minifyString: MinifyTextPipe,
    private datatableLang: DatatableLangService,
  ) {
  }

  renderDatatable(element, walletAddress, chain) {
    // console.log(walletAddress, 'address2');
    const chainId = this.ethersService.walletConnectNetworks[chain].chainId;
    this.datatable = $(element).DataTable({
      // language: this.datatableLang.lang,
      destroy: true,
      scrollX: true,
      buttons: [
        {
          text: '<i class="fas fa-sync" id="refresh-icon"></i>',
          action: async (e, dt, node, config) => {
            this.datatable.ajax.reload(null, false);
            $('#refresh-icon').removeClass('fas fa-sync').addClass('fas fa-sync fa-spin');
            await new Promise(f => setTimeout(f, 1000));
            $('#refresh-icon').removeClass('fas fa-sync fa-spin').addClass('fas fa-sync');
          },
          className: 'btn-table-refresh mr-3'
        }
      ],
      deferRender: true,
      scrollY: 'calc(100vh - 542px)',
      scrollResize: true,
      scroller: {
        loadingIndicator: true
      },
      order: [[5, 'asc']],
      dom: 't',
      serverSide: true,
      ajax: (dataTablesParameters: any, callback) => {
        // console.log(dataTablesParameters, 'dataTablesParameters');
        dataTablesParameters.columns.push(
          {
            'data': 'smart_contract_owner',
            'name': '',
            'searchable': true,
            'orderable': true,
            'search': {
              'value': walletAddress,
              'regex': false
            }
          });

        this.http
          .post(
            // 'https://api-nft.zertifier.com/api/smart-contracts/datatables',
            // 'http://localhost:3000/shippingPetitions',
            'http://46.253.45.22:3010/shippingPetitions',
            // {...dataTablesParameters, walletAddress, chainId}, {}
            {...dataTablesParameters, walletAddress, chainId}, {}
          ).subscribe(async (resp: any) => {
          /*for (const respElement of resp.data) {
            let tokenUri = await this.ethersService.getTokenUri(respElement.token_id, respElement.smart_contract,respElement.chain_id);
            // await axios.get(`http://46.253.45.22:3010/metaData?url=${tokenUri}`).then((metadata)=>{
            await axios.get(`http://localhost:3000/metaData?url=${tokenUri}`).then((metadata)=>{
              respElement.imageUrl = metadata.data.image;
              respElement.imageName = metadata.data.name;
            });

          }*/
          // console.log(resp, "resp");
          callback(resp);
        });
      },
      columns: [
        {
          data: 'token_id',
          defaultContent: '',
          orderable: false,
          searchable: false,
          width: '78px',
        },
        {
          data: 'token_id',
          defaultContent: '',
        },
        {
          data: 'smart_contract',
          defaultContent: ''
        },
        {
          data: 'chain_id',
          defaultContent: ''
        },
        {
          data: 'wallet_address',
          defaultContent: ''
        },
        {
          data: 'status',
          defaultContent: ''
        },
        {
          data: 'creation_dt',
          defaultContent: '',
          width: '80px',
        },
        {
          data: 'verify',
          defaultContent: '',
          orderable: false,
          searchable: false,
        },
      ],
      columnDefs: [
        {
          responsivePriority: 0,
          targets: 0,
          data: 'image',
          render: (id: string, type: string, data: any) => {
            // console.log(data, "data");
            /*let tokenUri: string = await this.ethersService.getTokenUri(data.token_id, data.smart_contract);
            if (tokenUri.startsWith('ipfs')) {
              tokenUri = tokenUri
                .replace('ipfs://', 'https://ipfs.io/ipfs/');
            }*/

            return `
                  <div class="text-center">
                    <img src="${data.image_url}"  class="table-img"
                    data-toggle="tooltip" data-placement="right" data-html="true"
                    title="<img src='${data.image_url}' class='tooltip-img'>">
                  </div>
                `;
            // return 'a'
            // let res = await axios.get(tokenUri);
            /*console.log(res, 'RESPONSE');
            return `
                  <img src="${res.data.image}" alt="${res.data.name}">
                `;*/

          }
        },
        {
          responsivePriority: 0,
          targets: 1,
          data: 'token_id',
          render: (id: string, type: string, data: any) => {
            // console.log(data, 'DATAAAA');
            return `
                    <div class="text-center">
                         <span>${data.token_id}</span>
                    </div>
                `;
          }
        },
        {
          responsivePriority: 3,
          targets: 2,
          data: 'smart_contract',
          render: (contractAddress: string, type: string, data: any) => {
            return `<i data-text="${contractAddress}" class="fas fa-copy mr-2 copy-data"></i>` + this.minifyString.transform(contractAddress);
          },
        },
        {
          responsivePriority: 0,
          targets: 3,
          data: 'chain_id',
          render: (id: string, type: string, data: any) => {
            return `
                    <div class="text-center">
                         <span>${data.chain_id}</span>
                    </div>
                `;
          }
        },
        {
          responsivePriority: 3,
          targets: 4,
          data: 'wallet_address',
          render: (WalletAdress: string, type: string, data: any) => {
            return `<i data-text="${WalletAdress}" class="fas fa-copy mr-2 copy-data"></i>` + this.minifyString.transform(WalletAdress);
          }
        },
        {
          responsivePriority: 3,
          targets: 5,
          data: 'status',
          render: (Status: string, type: string, data: any) => {
            if (Status === 'accepted') {
              return `<div class="text-center"><button class="btn btn-primary w-50 send-btn"
                      data-id="${data.token_id}"data-contract="${data.smart_contract}">
                        <i class="fas fa-shipping-fast"></i></button>
                      </div>`;
            } else if (Status === 'declined') {
              return `
                    <div class="text-center">
                         <i data-text="${Status}" style="color: red">${Status}</i>
                    </div>`;
            } else if (Status === 'sent') {
              return `<div class="text-center">
                        <button class="btn btn-success w-50" disabled>
                              <i class="fas fa-check text-white"
                                data-text="${Status}"></i>
                          </button>
                        </div>`;
            } else {
              return `
                    <div class="text-center">
                         <i data-text="${Status}" style="color: #cccc00">${Status}</i>
                    </div>`;
            }
          },
        },
        {
          responsivePriority: 1,
          targets: 6,
          data: 'creation_Dt',
          render: (creationDt: string, type: string, data: any) => {
            return creationDt && creationDt !== '0000-00-00 00:00:00' ? `<i class="far fa-calendar-alt mr-1"></i>${moment(creationDt).format('YYYY-MM-DD')}</br><i class="far fa-clock mr-1"></i>${moment(creationDt).format('HH:mm')}` : '';
          },
        },
        {
          responsivePriority: 1,
          targets: 7,
          data: 'verify',
          render: (id: string, type: string, data: any) => {
            return ` <div class="text-center"><button data-id="${data.token_id}"data-contract="${data.smart_contract}"
                      data-status="${data.status}" data-signature="${data.signature}" data-chain="${data.chain_id}"
                      class="btn btn-primary manage-btn">
                        Verify</button>
                      </div>`;
          },
        },
      ],
      drawCallback: () => {
        $('[data-toggle="tooltip"]').tooltip({boundary: 'window', trigger: 'hover'});
      }
    });
  }

  refreshDatatables() {
    this.datatable.ajax.reload(null, false);
  }

  getFormdata(tokenId: any, contractAddress: any) {
    const data = {tokenId, contractAddress};
    // return axios.post('http://localhost:3000/shippingData', data)
    return axios.post('http://46.253.45.22:3010/shippingData', data)
      .then((response) => {
        // return JSON.parse(response.data[0].data);
        // console.log(response.data[0], "RESPONSE");
        if (response.data[0] === undefined) return false
        else return JSON.parse(response.data[0].data);

      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  setStatus(tokenId: any, contractAddress: any, status: any) {
    console.log(status, 'status');
    if (status !== 'accepted' && status !== 'declined' && status !== 'sent') {
      return;
    } else {
      const data = {tokenId, contractAddress, status};
      // return axios.post('http://localhost:3000/shippingStatus', data)
      return axios.post('http://46.253.45.22:3010/shippingStatus', data)
        .then((response) => {
          // console.log(response, 'response');
          // return JSON.parse(response.data[0].data);
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
    }
  }

  search(value: string) {
    this.datatable.search(value).draw();
  }

  async refreshTable() {
    this.datatable.ajax.reload(null, false);
    $('#refresh-icon').removeClass('fas fa-sync').addClass('fas fa-sync fa-spin');
    await new Promise(f => setTimeout(f, 1000));
    $('#refresh-icon').removeClass('fas fa-sync fa-spin').addClass('fas fa-sync');
  }

}
