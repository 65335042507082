import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MinifyTextPipe} from './minify-text.pipe';
import {SafeUrlPipe} from './safe-url.pipe';
import { ReplacePipe } from './replace.pipe';
import {ObjectToArray} from './objectToArray.pipe';
import {JoinArrayPipe} from './joinArray.pipe';



@NgModule({
  declarations: [
    JoinArrayPipe,
    MinifyTextPipe,
    SafeUrlPipe,
    ReplacePipe,
    ObjectToArray
  ],
  imports: [
    CommonModule
  ],
  exports: [
    JoinArrayPipe,
    MinifyTextPipe,
    SafeUrlPipe,
    ReplacePipe,
    ObjectToArray
  ],
  providers: [
    JoinArrayPipe,
    MinifyTextPipe,
    SafeUrlPipe,
    ReplacePipe,
    ObjectToArray
  ]
})
export class PipesModule { }
